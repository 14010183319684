import { useEffect, useState } from "react";
import { BirimlerApi } from "../../Api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SwalConfirm, SwalWait, Toast } from "../../components/Swal";
import { BirimDeleteApi } from "../../Api";
import Header from "../../components/Header";

export default function BirimlerView() {
    const [birimler, setBirimler] = useState([]);
    const token = useSelector(state => state.user.value.token);
    
    useEffect(() => {
        const popup = SwalWait.fire();
        (async () => {
            const BirimRes = await BirimlerApi(token);
            if(BirimRes.result)
                setBirimler(BirimRes.result);

            popup.close();
        })();
        document.title = "Birimler";
    }, [token]);



    const HandleDelete = async (id) => {
        const confirm = await SwalConfirm.fire({
            icon: 'question',
            title: 'Emin misin?',
            text: 'Veri sistemden kalıcı olarak silinecektir!', 
        });
    
        if(confirm.isConfirmed) {
            const DeleteResult = await BirimDeleteApi(token, id);
            if(DeleteResult.status) {
                setBirimler(birimler.filter(c => c.id !== id));
                await Toast.fire({
                    icon: 'success',
                    title: DeleteResult.message
                });
            }

            if(!DeleteResult.status) {
                await Toast.fire({
                    icon: 'error',
                    title: DeleteResult.message
                });
            }
        }
    }

    return (
        <div>
            <Header title="Birimler" link="/birim/form" />
        
            <div className="table-responsive">
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th>Uygulama</th>
                        <th>Ad</th>
                        <th>Detaylar</th>
                        <th>Tanım</th>
                        <th>Etiket</th>
                        <th width="170"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {birimler.map(birim => (
                        <tr key={birim.id}>
                            <td>{birim.uygulama ? birim.uygulama.tanim : ''}</td>
                            <td>{birim.ad}</td>
                            <td>
                                <ul className="list-unstyled">
                                    {birim.detail.map(detail => (
                                        <li key={detail.id}>{detail.miktar}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>{birim.tanim ?? ''}</td>
                            <td>{birim.label}</td>
                            <td>
                                <Link to={'/birim/detay/' + birim.id} className="btn btn-primary btn-sm me-1">Detay</Link>
                                <Link to={'/birim/duzenle/' + birim.id} className="btn btn-secondary btn-sm me-1">Düzenle</Link>
                                <button className="btn btn-danger btn-sm" onClick={() => HandleDelete(birim.id)}>Sil</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}