import { useEffect, useState } from "react";
import { LoginApi } from "../../Api";
import { useDispatch } from "react-redux";
import { Toast } from "../../components/Swal";
import { login } from "../../store/UserSlice";

export default function Login() {
    const [formField, setFormField] = useState({email: '', password: ''});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        document.title = "Sisteme Giriş";
    }, []);

    const dispatch = useDispatch();

    const HandleSubmit = async (e) => {
        e.preventDefault();

        const LoginRes = await LoginApi(formField);
        if(LoginRes.status){
            dispatch(login(LoginRes.result));
        } else {
            setErrors(LoginRes.result ?? [])
            await Toast.fire({
                icon: 'error',
                title: LoginRes.message
            });
        }

    }
    return (
        <>
            <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <div className="col-12 col-sm-4">
                    <form onSubmit={HandleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email">Eposta</label>
                            <input 
                                type="email" 
                                className={errors.email ? "form-control is-invalid text-danger" : "form-control"}
                                id="email" 
                                value={formField.email}
                                onChange={e => setFormField({...formField, email: e.target.value})}></input>
                            {errors.email && (
                                <small className="text-danger">{errors.email}</small>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password">Şifre</label>
                            <input 
                                type="password" 
                                className={errors.password ? "form-control is-invalid text-danger" : "form-control"}
                                id="password"
                                value={formField.password}
                                onChange={e => setFormField({...formField, password: e.target.value})}></input>
                            {errors.password && (
                                <small className="text-danger">{errors.password}</small>
                            )}
                        </div>
                        <div className="d-grid gap-2">
                            <button className="btn btn-primary form-control" type="Submit">Giriş Yap</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}