import { createSlice } from '@reduxjs/toolkit'

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    value: JSON.parse(localStorage.getItem('user')) ?? null
  },
  reducers: {
    login: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.value = action.payload;
    },

    logout: (state, action) => {
        localStorage.removeItem('user');
        state.value = null;
    },

    profileEdit: (state, action) => {
        state.value.name = action.payload.name;
        state.value.email = action.payload.email;
    },
  }
})

export const { login, logout, profileEdit } = UserSlice.actions

export default UserSlice.reducer