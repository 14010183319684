import { useEffect, useState } from "react";
import { BirimDetayApi, BirimSaveApi, UygulamalarApi } from "../../Api";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { Toast } from "../../components/Swal";
import { useNavigate, useParams } from "react-router-dom";

export default function BirimFormView({navigation}) {
    const [uygulamalar, setUygulamalar] = useState([]);
    const [formField, setFormField] = useState({
        tanim: '',
        ad: '',
        uygulama_id: '',
        label: ''
    });
    const token = useSelector(state => state.user.value.token);

    const navigate = useNavigate();

    const {id} = useParams();



    useEffect(() => {
        (async () => {
            const res = await UygulamalarApi(token);
            if(res.result)
                setUygulamalar(res.result);
        })();

        if(id) {
            (async () => {
                const res = await BirimDetayApi(token, id);
                if(res.status)
                    setFormField(res.result);
            })();
        }

        document.title = "Birim Formu";
    }, [id, token]);





    const HandleSubmit = async e => {
        e.preventDefault();

        const res = await BirimSaveApi(token, formField);
        Toast.fire({
            icon: res.status ? "success" : "error",
            title: res.message
        });

        if(res.status){
            setFormField({
                tanim: '',
                ad: '',
                uygulama_id: '',
                label: ''
            });
            
            const birim = res.result;
            navigate('/birim/detay/' + birim.result.id);
        }
    }


    return (
        <>
            <Header title="Birim Formu" />

            <div>
                <form action="" method="post" onSubmit={HandleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="tanim">Tanım</label>
                        <input 
                        type="text" 
                        name="tanim" 
                        id="tanim" 
                        className="form-control"
                        value={formField.tanim}
                        onChange={e => setFormField({...formField, tanim: e.target.value})} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="ad">Ad</label>
                        <input 
                        type="text" 
                        name="ad" 
                        id="ad" 
                        className="form-control"
                        value={formField.ad}
                        onChange={e => setFormField({...formField, ad: e.target.value})}  />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="uygulama_id">Uygulama</label>
                        <select 
                        className="form-select" 
                        id="uygulama_id" 
                        name="uygulama_id"
                        value={formField.uygulama_id}
                        onChange={e => setFormField({...formField, uygulama_id: e.target.value})}>
                            <option value={null}>Seçim Yapın...</option>
                            {uygulamalar.map(uygulama => (
                                <option key={uygulama.id} value={uygulama.id}>{uygulama.tanim}</option>
                            ))}                                                            
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="label">Etiket</label>
                        <input 
                        type="text" 
                        name="label" 
                        id="label" 
                        className="form-control"
                        value={formField.label}
                        onChange={e => setFormField({...formField, label: e.target.value})} />
                    </div>

                    <button className="btn btn-primary" type="submit">Kaydet</button>
                </form>
            </div>
        </>
    );
}