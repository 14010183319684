import axios from "axios";
import { prefix } from './AxiosSettings';


export const LoginApi = async (data) => {
    try {
        const result = await axios.post('auth/login', JSON.stringify(data));
        return {
            status: true,
            result: result.data,
            message: "Tebrikler, giriş yapıyorsunuz.",
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Giriş yapılamadı!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const UygulamalarApi = async (token) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.get("uygulama");
        return {
            status: true,
            result: result.data,
            message: null,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: null,
                message: error.response.data
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const UygulamaDeleteApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.delete("uygulama/sil/" + id);
        return {
            status: true,
            result: null,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler silinemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const UygulamaGetApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.get("uygulama/" + id);
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler getirilemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const UygulamaSaveApi = async (token, data) => {
    axios.defaults.headers.Authorization = prefix + token;
    try {
        const result = await axios.post("uygulama/kaydet", JSON.stringify(data));
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        console.log("error",error)
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler kaydedilemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}




export const BirimlerApi = async (token) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.get("birim");
        return {
            status: true,
            result: result.data,
            message: null,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: null,
                message: error.response.data
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const BirimDetayApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.get("birim/detay/" + id);
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: null,
                message: error.response.data
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const BirimSaveApi = async (token, data) => {
    axios.defaults.headers.Authorization = prefix + token;
    try {
        const result = await axios.post("birim/kaydet", JSON.stringify(data));
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler kaydedilemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const BirimDetaySaveApi = async (token, data) => {
    axios.defaults.headers.Authorization = prefix + token;
    try {
        const result = await axios.post("birim/detay/kaydet", JSON.stringify(data));
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        console.log("error",error)
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler kaydedilemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const BirimDetayDeleteApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.delete("birim/detay/sil/" + id);
        return {
            status: true,
            result: null,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler silinemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const BirimDeleteApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.delete("birim/sil/" + id);
        return {
            status: true,
            result: null,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler silinemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}






export const UrunlerApi = async (token) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.get("urun");
        return {
            status: true,
            result: result.data,
            message: null,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: null,
                message: error.response.data
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const UrunDeleteApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.delete("urun/sil/" + id);
        return {
            status: true,
            result: null,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler silinemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const UrunSaveApi = async (token, data) => {
    axios.defaults.headers.Authorization = prefix + token;
    try {
        const result = await axios.post("urun/kaydet", JSON.stringify(data));
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler kaydedilemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const UrunDetayApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.get("urun/" + id);
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: null,
                message: error.response.data
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}




export const SonucApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.get("sonuc/" + id);
        return {
            status: true,
            result: result.data,
            message: null,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: null,
                message: error.response.data
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const SonucSaveApi = async (token, data) => {
    axios.defaults.headers.Authorization = prefix + token;
    try {
        const result = await axios.post("sonuc/kaydet", JSON.stringify(data));
        return {
            status: true,
            result: result.data,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler kaydedilemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}

export const SonucDeleteApi = async (token, id) => {
    axios.defaults.headers.Authorization = prefix + token;

    try {
        const result = await axios.delete("sonuc/sil/" + id);
        return {
            status: true,
            result: null,
            message: result.data.message,
        };
    } catch(error){
        if(error.response){
            return {
                status: false,
                result: error.response.data.errors ? error.response.data.errors : null,
                message: typeof error.response.data === "string" ? error.response.data : "Bilgiler silinemedi!"
            }
        }else if(error.request){
            return {
                status: false,
                result: null,
                message: error.message
            }
        }else{
            return {
                status: false,
                result: null,
                message: "Bir sorun oluştu!"
            }
        }
    }
}