import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { UrunlerApi, UrunDeleteApi } from "../../Api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SwalConfirm, SwalWait, Toast } from "../../components/Swal";

export default function UrunlerView() {
    const [urunler, setUrunler] = useState([]);
    const token = useSelector(state => state.user.value.token);



    useEffect(() => {
        const popup = SwalWait.fire();
        (async () => {
            const res = await UrunlerApi(token);
            if(res.status)
                setUrunler(res.result)

            popup.close();
        })();
        document.title = "Ürünler";
    }, []);


    const HandleDelete = async (id) => {
        const confirm = await SwalConfirm.fire({
            icon: 'question',
            title: 'Emin misin?',
            text: 'Veri sistemden kalıcı olarak silinecektir!', 
        });
    
        if(confirm.isConfirmed) {
            const DeleteResult = await UrunDeleteApi(token, id);
            console.log(DeleteResult)
            if(DeleteResult.status) 
                setUrunler(urunler.filter(c => c.id !== id));


            await Toast.fire({
                icon: DeleteResult.status ? 'success' : 'error',
                title: DeleteResult.message
            });
        }
    }


    return (
        <>
            <Header title="Ürünler" link="/urun/form" />

            <div className="table-responsive">
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th>Tanım</th>
                        <th width="115"></th>
                    </tr>
                    </thead>
                    <tbody>
                        {urunler.map(urun => (
                            <tr key={urun.id}>
                                <td>{urun.tanim}</td>
                                <td>
                                    <Link to={"/urun/duzenle/" + urun.id} className="btn btn-secondary btn-sm me-1">Düzenle</Link>
                                    <button className="btn btn-danger btn-sm" onClick={() => HandleDelete(urun.id)}>Sil</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}