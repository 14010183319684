import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'xsrfHeaderName': 'X-XSRF-TOKEN',
    'withCredentials': true,
}


export const prefix = "Bearer ";