import Login from "./Views/Auth/Login";
import App from "./App";
import { useSelector } from "react-redux/es/hooks/useSelector";

export default function AuthCheck() {
    const user = useSelector(state => state.user.value);

    if(user) {
        return <App />
    } else {
        return <Login />
    }
}