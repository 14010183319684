import { createBrowserRouter } from "react-router-dom";

import UygulamalarView from "./Views/Uygulama/UygulamalarView";
import UygulamaFormView from "./Views/Uygulama/UygulamaFormView";
import BirimlerView from "./Views/Birim/BirimlerView";
import BirimDetailView from "./Views/Birim/BirimDetailView";
import BirimDetailItemView from "./Views/Birim/BirimDetailItemView";
import BirimFormView from "./Views/Birim/BirimFormView";
import UrunlerView from "./Views/Urun/UrunlerView";
import UrunFormView from "./Views/Urun/UrunFormView";



const router = createBrowserRouter([
    {
        path: "/",
        element: <UygulamalarView />,
    },
    {
        path: "/uygulama",
        element: <UygulamalarView />,
    },
    {
        path: "/uygulama/form",
        element: <UygulamaFormView />,
    },
    {
        path: "/uygulama/duzenle/:id",
        element: <UygulamaFormView />,
    },

    {
        path: "/birim",
        element: <BirimlerView />,
    },
    {
        path: "/birim/detay/:id",
        element: <BirimDetailView />,
    },
    {
        path: "/birim/detay/item/:id",
        element: <BirimDetailItemView />,
    },
    {
        path: "/birim/form",
        element: <BirimFormView />,
    },
    {
        path: "/birim/duzenle/:id",
        element: <BirimFormView />,
    },

    {
        path: "/urunler",
        element: <UrunlerView />
    },
    {
        path: "/urun/form",
        element: <UrunFormView />
    },
    {
        path: "/urun/duzenle/:id",
        element: <UrunFormView />
    }
]);

export default router;