import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { UrunSaveApi, UrunDetayApi } from "../../Api";
import { useSelector } from "react-redux";
import { Toast } from "../../components/Swal";
import { useNavigate, useParams } from "react-router-dom";

export default function UrunFormView() {
    const [formField, setFormField] = useState({
        tanim: '',
        sira: ''
    });
    const token = useSelector(state => state.user.value.token);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if(id) {
            (async () => {
                const res = await UrunDetayApi(token, id);
                console.log(res)
                if(res.status)
                    setFormField(res.result);
            })();
        }
        document.title = "Ürün Formu";
    }, [id, token]);

    const HandleSubmit = async e => {
        e.preventDefault();

        const res = await UrunSaveApi(token, formField);
        Toast.fire({
            icon: res.status ? 'success' : 'error',
            title: res.message
        });        

        if(res.status)
            navigate('/urunler');
    }

    return (
        <>
            <Header title="Ürün Formu" />
            <form action="" method="post" onSubmit={HandleSubmit}>
                <div className="mb-3">
                    <label htmlFor="tanim">Tanım</label>
                    <input 
                    type="text" 
                    name="tanim" 
                    id="tanim" 
                    className="form-control"
                    value={formField.tanim}
                    onChange={e => setFormField({...formField, tanim: e.target.value})}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="sira">Sıra</label>
                    <input 
                    type="number" 
                    name="sira" 
                    id="sira" 
                    className="form-control" 
                    value={formField.sira}
                    onChange={e => setFormField({...formField, sira: e.target.value})} />
                </div>
                <button className="btn btn-primary" type="submit">Kaydet</button>
            </form>
        </>
    );
}