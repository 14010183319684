import { useEffect, useState } from "react";
import { UygulamalarApi } from "../../Api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { SwalConfirm } from "../../components/Swal";
import { UygulamaDeleteApi } from "../../Api";
import { Toast, SwalWait } from "../../components/Swal";


export default function UygulamalarView() {
    const [uygulamalar, setUygulamalar] = useState([]);
    const token = useSelector(state => state.user.value.token);
    
    useEffect(() => {
        const popup = SwalWait.fire();
        (async () => {
            const UygulamaRes = await UygulamalarApi(token);
            if(UygulamaRes.result)
                setUygulamalar(UygulamaRes.result);

            popup.close();
        })();
        document.title = "Uygulamalar";
    }, [token]);



    const HandleDelete = async (id) => {
        const confirm = await SwalConfirm.fire({
            icon: 'question',
            title: 'Emin misin?',
            text: 'Veri sistemden kalıcı olarak silinecektir!', 
        });
    
        if(confirm.isConfirmed) {
            const DeleteResult = await UygulamaDeleteApi(token, id);
            if(DeleteResult.status) 
                setUygulamalar(uygulamalar.filter(c => c.id !== id));

            await Toast.fire({
                icon: DeleteResult.status ? 'success' : 'error',
                title: DeleteResult.message
            });
        }
    }

    return (
        <div>
            <Header title="Uygulamalar" link="/uygulama/form" />
        
            <div className="table-responsive">
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th>Uygulama Adı</th>
                        <th>Uygulama Resmi</th>
                        <th>Button Resmi</th>
                        <th width="120"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {uygulamalar.map(uygulama => (
                        <tr key={uygulama.id}>
                            <td>{uygulama.tanim}</td>
                            <td>{uygulama.image}</td>
                            <td>{uygulama.image_button}</td>
                            <td>
                                <Link to={'/uygulama/duzenle/' + uygulama.id} className="btn btn-secondary btn-sm me-1">Düzenle</Link>
                                <button className="btn btn-danger btn-sm" onClick={() => HandleDelete(uygulama.id)}>Sil</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}