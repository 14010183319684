import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BirimDetayApi, BirimDetaySaveApi, BirimDetayDeleteApi } from "../../Api";
import { useSelector } from "react-redux";
import { SwalConfirm, Toast } from "../../components/Swal";
import Header from "../../components/Header";

export default function BirimDetailView() {
    const [birim, setBirim] = useState(null);
    const [miktar, setMiktar] = useState('')
    const token = useSelector(state => state.user.value.token)
    const {id} = useParams();

    useEffect(() => {
        (async () => {
            const res = await BirimDetayApi(token, id);
            if(res.status)
                setBirim(res.result);
        })();
        document.title = "Birim Detayı";
    }, [id, token]);


    const HandleSave = async (e) => {
        e.preventDefault();

        const res = await BirimDetaySaveApi(token, {miktar: miktar, birim_id: id});
        if(res.status){
            setMiktar('');
            const detaylar = birim.detail;
            detaylar.push(res.result.result);
            setBirim({...birim, detail: detaylar})
        }

        await Toast.fire({
            icon: res.status ? "success" : "error",
            title: res.message
        });
    }


    const HandleDelete = async (id) => {
        const confirm = await SwalConfirm.fire({
            icon: 'question',
            title: 'Emin misin?',
            text: 'Veri sistemden kalıcı olarak silinecektir!', 
        });

        if(confirm.isConfirmed) {
            const res = await BirimDetayDeleteApi(token, id);

            Toast.fire({
                icon: res.status ? "success" : "error",
                title: res.message
            });

            const detaylar = birim.detail.filter(c => c.id !== id);
            setBirim({...birim, detail: detaylar});
        }
    }


    if(!birim)
        return null;

    return (
        <>
            <Header title="Birim Detay" link="/birim/form" />
            {birim && (
                <div>
                    <div className="row">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table table-sm">
                                    <tbody>
                                    <tr>
                                        <th width="150">Tanım</th>
                                        <td>{birim.tanim}</td>
                                    </tr>
                                    <tr>
                                        <th>Ad</th>
                                        <td>{birim.ad}</td>
                                    </tr>
                                    <tr>
                                        <th>Etiket</th>
                                        <td>{birim.label}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Link to="/birim" className="btn btn-secondary btn-sm">Geri</Link>
                        </div>

                        <div className="col">
                            <h2 className="h6"></h2>
                            <table className="table table-sm">
                                <tbody>
                                {birim.detail.map(d => (
                                    <tr key={d.id}>
                                        <td>
                                            <Link to={"/birim/detay/item/" + d.id} className="link-dark link-underline-light">{d.miktar}</Link>
                                        </td>
                                        <td width="40">
                                            <button onClick={() => HandleDelete(d.id)} className="btn btn-danger btn-sm">Sil</button>
                                        </td>
                                    </tr>
                                ))}    
                                </tbody>                            
                            </table>
                            <button className="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#offcanvasyeni" aria-controls="offcanvasyeni">Yeni Ekle</button>
                        </div>
                    </div>
                    

                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasyeni" aria-labelledby="offcanvasyeniLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasyeniLabel">Yeni Detay Ekleme Formu</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <form action="" method="post" onSubmit={HandleSave}>
                                <div className="mb-3">
                                    <label htmlFor="miktar">Miktar</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="miktar" 
                                        name="miktar"
                                        value={miktar}
                                        onChange={e => setMiktar(e.target.value)} />
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-primary">Kaydet</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
   );
}