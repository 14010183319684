import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { SonucApi, SonucDeleteApi, SonucSaveApi } from "../../Api";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { SwalConfirm, Toast } from "../../components/Swal";


export default function BirimDetailItemView() {
    const { id } = useParams();
    const token = useSelector(state => state.user.value.token ?? null);
    const [birim, setBirim] = useState({});
    const [detay, setDetay] = useState({});
    const [urunler, setUrunler] = useState([]);
    const [sonuc, setSonuc] = useState([]);
    const [formField, setFormField] = useState({urun_id: '', miktar: '1', birim_detay_id: id});

    useEffect(() => {
        (async () => {
            const res = await SonucApi(token, id);
            if(res.status) {
                setBirim(res.result.detay.birim);
                setDetay(res.result.detay);
                setSonuc(res.result.detay.sonuc);
                setUrunler(res.result.urunler);
            }
        })();
    }, []);

    if(!birim)
        return null;


    const HandleDelete = async (id) => {
        const confirm = await SwalConfirm.fire({
            icon: 'question',
            title: 'Emin Misin?',
            text: 'Veri sistemden kalıcı olarak silinecektir.'
        });

        if(confirm.isConfirmed) {
            const res = await SonucDeleteApi(token, id);
            if(res.status)
                setSonuc([...sonuc.filter(c => c.id !== id)]);
        }
    }


    const HandleSubmit = async (e) => {
        e.preventDefault();
        
        const res = await SonucSaveApi(token, formField);
        if(res.status) {
            let sonuclar = [...sonuc];
            sonuclar.push(res.result.result)
            setSonuc(sonuclar);
        }
        await Toast.fire({
            icon: res.status ? 'success' : 'error',
            text: res.message
        });
    }


    return (
        <>
            <Header title="Birim Detay" />
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table table-sm">
                            <tbody>
                            <tr>
                                <th>Birim</th>
                                <td>{birim.tanim ?? ''}</td>
                            </tr>
                            <tr>
                                <th width="150">Tanım</th>
                                <td>{detay.miktar}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <Link to={"/birim/detay/" + birim.id} className="btn btn-secondary btn-sm">Geri</Link>
                </div>

                <div className="col">
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th>Ürün</th>
                            <th width="100">Miktar</th>
                            <th width="40"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {sonuc.map(detail => (
                        <tr key={detail.id}>
                            <td>{detail.urun.tanim ? detail.urun.tanim : ''}</td>
                            <td>{detail.miktar}</td>
                            <td width="40">
                                <button className="btn btn-danger btn-sm" onClick={() => HandleDelete(detail.id)}>Sil</button>
                            </td>
                        </tr>
                        ))}
                        </tbody>
                    </table>
                    <button className="btn btn-primary btn-sm float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvasyeni" aria-controls="offcanvasyeni">Yeni Ekle</button>
                </div>
            </div>





            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasyeni" aria-labelledby="offcanvasyeniLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasyeniLabel">Yeni Detay Ekleme Formu</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <form action="" method="post" autoComplete="off" onSubmit={HandleSubmit}>
                        <input type="hidden" name="birim_detay_id" value="{{$detay->id}}" />

                        <div className="mb-3">
                            <label htmlFor="urun_id">Ürün</label>
                            <select 
                            className="form-select" 
                            id="urun_id" 
                            name="urun_id"
                            value={formField.urun_id}
                            onChange={e => setFormField({...formField, urun_id: e.target.value})}>
                            <option value="">Seçim Yapın...</option>
                            {urunler.map(urun => (
                                <option key={urun.id} value={urun.id}>{urun.tanim}</option>
                            ))}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="miktar">Miktar</label>
                            <input 
                            type="number" 
                            className="form-control" 
                            id="miktar" 
                            name="miktar" 
                            value={formField.miktar}
                            onChange={e => setFormField({...formField, miktar: e.target.value})}
                            />
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn btn-primary">Kaydet</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}