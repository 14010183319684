import React from "react";
import { Link } from "react-router-dom";


function Header({title, link}) {
    return (
        <div className="row mt-4 mb-3">
            <div className="col">
                <h1 className="h5">{title}</h1>
            </div>
            <div className="col text-end">
                {link && (
                    <Link to={link} className="btn btn-primary">Yeni Ekle</Link>
                )}
            </div>
        </div>
    );
}

export default Header;