import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UygulamaGetApi, UygulamaSaveApi } from "../../Api";
import { useSelector } from "react-redux";
import { SwalWait, Toast } from "../../components/Swal";

export default function UygulamaFormView({navigation}) {
    const [uygulama, setUygulama] = useState({
        tanim: '',
        image: '',
        image_button: ''
    });
    const {id} = useParams();
    const token = useSelector(state => state.user.value.token);


    useEffect(() => {
        const popup = SwalWait.fire();
        (async () => {
            const res = await UygulamaGetApi(token, id);
            if(res.result)
                setUygulama(res.result);
            
            popup.close();
        })();
        document.title = "Uygulama Formu";
    }, [id, token]);

    const HandleSubmit = async (e) => {
        e.preventDefault();
        const UpdateRes = await UygulamaSaveApi(token, uygulama);
        Toast.fire({
            icon: UpdateRes.status ? "success" : "error",
            title: UpdateRes.message
        });

        if(UpdateRes.status)
            navigation.navigate("/uygulama");

    }
    
    return (
        <>
            <Header title="Uygulama Form" />
            <form action="" method="post" onSubmit={HandleSubmit}>
                <div className="mb-3">
                    <label htmlFor="tanim">Uygulama Adı</label>
                    <input 
                    type="text" 
                    name="tanim" 
                    id="tanim" 
                    className="form-control"
                    value={uygulama.tanim}
                    onChange={e => setUygulama({...uygulama, tanim: e.target.value})} />
                </div>
                <div className="mb-3">
                    <label htmlFor="image">Uygulama Resmi</label>
                    <input 
                    type="text" 
                    name="image" 
                    id="image" 
                    className="form-control"
                    value={uygulama.image}
                    onChange={e => setUygulama({...uygulama, image: e.target.value})} />
                </div>
                <div className="mb-3">
                    <label htmlFor="image_button">Button Resmi</label>
                    <input 
                    type="text" 
                    name="image_button" 
                    id="image_button" 
                    className="form-control"
                    value={uygulama.image_button}
                    onChange={e => setUygulama({...uygulama, image_button: e.target.value})} />
                </div>
                <button className="btn btn-primary" type="submit">Kaydet</button>
            </form>
        </>
    );
}